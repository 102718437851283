<template>
  <v-container fluid class="content-wrapper mb-6">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("SigninWhatNearBy.title") }}</h4>
      <v-btn icon class="ml-3" @click="initAll()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <v-row row wrap>
      <v-col cols="9">
        <div class="card card-shadow pa-5">
          <form v-if="loadingData" class="content register-content">
            <v-skeleton-loader
              type="list-item-three-line, divider, list-item-three-line, divider, list-item-three-line"
            ></v-skeleton-loader>
          </form>

          <form v-else-if="!loadingData && itemsCategory.length" class="content register-content">
            <div class="scroll-wrapper scrollbar-inner overflow-y-auto">
              <template v-for="(item, index) in itemsCategory">
                <div :key="index" class="d-flex flex-column">
                  <h3 class="title-sm mb-0" :class="index !== 0 && 'mt-3'">
                    {{ item.main_category.value }}
                  </h3>
                  <v-card outlined class="mt-4 pa-3">
                    <template v-for="(element, i) in item.category">
                      <div :key="element._id" class="d-flex flex-column mt-2 mb-1">
                        <h3 class="title-sm mb-2">{{ element.value }}</h3>
                        <v-row
                          v-for="(ele, i) in element.nearbyItems"
                          :key="ele._id"
                          class="my-0"
                        >
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="4"
                            class="d-flex flex-column align-start justify-center my-0"
                            :key="componentKey"
                          >
                            <span class="mb-3" v-if="i == 0">
                              {{ $t("SigninWhatNearBy.name") }}
                            </span>
                            <div class="form-group mb-0" style="width: 100%">
                              <input
                                type="text"
                                class="form-control"
                                name
                                placeholder
                                v-model="ele.name"
                                :disabled="!ele.editList"
                              />
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="4"
                            class="d-flex flex-column align-start justify-center my-0"
                          >
                            <span class="mb-3" v-if="i == 0">
                              {{ $t("SigninWhatNearBy.distance") }}
                            </span>
                            <div class="form-group mb-0 d-flex flex-row">
                              <v-text-field
                                v-model="ele.distance.total"
                                label
                                class="form-control"
                                type="number"
                                min="0"
                                solo
                                flat
                                dense
                                single-line
                                hide-details
                                :disabled="!ele.editList"
                                @keypress="isNumber($event)"
                              ></v-text-field>

                              <!-- <template v-slot:append-outer> -->
                              <v-select
                                v-model="ele.distance.unit._id"
                                :items="itemsMeasure"
                                item-text="value"
                                item-value="_id"
                                label
                                class="mb-0"
                                style="border: 1px solid #828282; border-radius: 4px"
                                solo
                                flat
                                dense
                                single-line
                                hide-details
                                :disabled="!ele.editList"
                              ></v-select>
                              <!-- </template> -->
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            class="d-flex flex-row align-end justify-start"
                            style="width: 100%"
                          >
                            <v-btn
                              v-if="!ele.editList"
                              block
                              outlined
                              style="min-height: 42px"
                              color="warning"
                              @click="toggleEditListFunc(ele)"
                              >{{ $t("SigninWhatNearBy.btn_edit") }}</v-btn
                            >
                            <v-btn
                              v-else
                              block
                              outlined
                              style="min-height: 42px"
                              color="deep-purple lighten-2"
                              @click="saveListFunc(ele)"
                              :disabled="!ele.name || !ele.distance.total"
                              >{{ $t("SigninWhatNearBy.btn_save") }}</v-btn
                            >
                            <!-- <v-btn icon @click="removeListFunc(ele)">
                              <v-icon>mdi-close</v-icon>
                            </v-btn> -->
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            class="d-flex flex-row align-end justify-start"
                            style="width: 100%"
                          >
                            <v-btn
                              block
                              outlined
                              style="min-height: 42px"
                              color="error lighten-2"
                              @click="removeListFunc(ele)"
                              :disabled="ele.editList"
                              >{{ $t("SigninWhatNearBy.btn_del") }}</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="4"
                            class="d-flex flex-column align-start justify-end"
                          >
                            <span class="mb-1" v-if="element.nearbyItems.length == 0">{{
                              $t("SigninWhatNearBy.name")
                            }}</span>
                            <div class="form-group mb-0" style="width: 100%">
                              <input
                                type="text"
                                class="form-control"
                                style="width: 100%"
                                name
                                placeholder
                                v-model="element.addnew_name"
                              />
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="4"
                            class="d-flex flex-column align-start justify-end contain-distance"
                          >
                            <span class="mb-1" v-if="element.nearbyItems.length == 0">{{
                              $t("SigninWhatNearBy.distance")
                            }}</span>
                            <div class="form-group mb-0 d-flex flex-row">
                              <v-text-field
                                v-model="element.addnew_distance_number"
                                label
                                class="form-control"
                                style="max-height: 42px !important; width: 100%"
                                type="number"
                                min="0"
                                solo
                                flat
                                dense
                                single-line
                                hide-details
                                @keypress="isNumber($event)"
                              ></v-text-field>
                              <!-- <template v-slot:append> -->
                              <v-select
                                v-model="element.addnew_distance_unit"
                                :items="itemsMeasure"
                                item-text="value"
                                item-value="_id"
                                label
                                class="mb-0"
                                style="border: 1px solid #828282; border-radius: 4px"
                                solo
                                flat
                                dense
                                single-line
                                hide-details
                              ></v-select>
                              <!-- </template> -->
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            class="d-flex flex-row align-end justify-start"
                            style="width: 100%"
                          >
                            <v-btn
                              block
                              outlined
                              style="min-height: 42px"
                              color="deep-purple lighten-2"
                              @click="addNewFunc(element)"
                              :disabled="
                                !element.addnew_name || !element.addnew_distance_number
                              "
                              >{{ $t("SigninWhatNearBy.btn_save") }}</v-btn
                            >
                          </v-col>
                          <!-- <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            class="d-flex flex-row align-end justify-start"
                            style="width: 100%"
                          >
                            <v-btn
                              block
                              outlined
                              style="min-height: 42px"
                              @click="clearAddNewFunc(element)"
                            >{{ $t("SigninWhatNearBy.btn_clear") }}</v-btn>
                          </v-col> -->
                        </v-row>
                        <v-divider v-if="i + 1 !== item.category.length" />
                      </div>
                    </template>
                  </v-card>
                </div>
              </template>
            </div>
          </form>
        </div>
      </v-col>
      <v-col cols="3">
        <v-card v-if="loadingData">
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </v-card>
        <v-card v-else class="pa-5">
          <div class="inner d-flex flex-column">
            <h3 class="title-sm mb-0">{{ $t("SigninWhatNearBy.titletell") }}</h3>
            <label class="text-muted mb-0">
              {{ $t("SigninWhatNearBy.labeltell") }}
            </label>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import _ from "lodash";

export default {
  name: "SigninWhatNearByScreen",
  metaInfo: {
    title: "What Near By",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    propertyData: null,
    itemsMainCategory: [],
    itemsCategory: [],
    itemsOldCategory: [],
    itemsMeasure: [],
    mockupMeasureUnit: "",
    componentKey: 0,
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    initAll() {
      const self = this;
      self.loadingData = true;
      self.componentKey = 0;
      self.initMeasure();
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        self.initMainCategory();
      }
    },
    async initMainCategory() {
      const self = this;
      var temp = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/near_by_main_category",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log('main: ', res?.data?.result)
          temp = res?.data?.result || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.itemsMainCategory = temp;
        self.initAllCategory();
      }
    },
    async initAllCategory() {
      const self = this;
      var temp = null;
      var tempOld = null;
      var tempHave = [];
      var tempMerge = [];
      self.loadingData = true;
      EventBus.$emit("loadingtillend");
      self.mockupMeasureUnit = self?.itemsMeasure?.find(
        (p) => p.value == "กิโลเมตร" || p.value == "kilometers" || p.value == "公里"
      );

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/near_by_category",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (self?.propertyData?.near_by?.length) {
            var values = _.groupBy(self?.propertyData?.near_by, "category._id");
            tempHave = Object.keys(values).map((key) => {
              return {
                main_category_id: values[key]?.map((el) => el?.category?.main_category)[0]
                  ._id,
                main_category: values[key]?.map((el) => el?.category?.main_category)[0],
                category: values[key]?.map((el) => el?.category)[0],
                value: values[key]?.map((el) => el?.category)[0].value,
                _id: key,
                nearbyItems: values[key],
                addnew_name: "",
                addnew_distance_number: 1,
                addnew_distance_unit: self?.mockupMeasureUnit?._id || "",
              };
            });
          }
          res?.data?.result?.map((el) => {
            const dup = tempHave?.find((p) => p._id == el._id);
            if (dup?._id) {
              tempMerge.push(dup);
            } else {
              tempMerge.push({
                main_category_id: el?.main_category?._id,
                main_category: el?.main_category,
                category: el,
                value: el?.value,
                _id: el?._id,
                nearbyItems: [],
                addnew_name: "",
                addnew_distance_number: 1,
                addnew_distance_unit: self?.mockupMeasureUnit?._id || "",
              });
            }
          });
          var group = _.groupBy(tempMerge || res?.data?.result, "main_category._id");
          // console.log('group: ', group)
          temp = Object.keys(group).map((key) => {
            return {
              main_category_id: key,
              main_category: group[key].map((el) => el.main_category)[0],
              category: group[key],
            };
          });
          tempOld = Object.keys(group).map((key) => {
            return {
              main_category_id: key,
              main_category: group[key].map((el) => el.main_category)[0],
              category: group[key],
            };
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.itemsCategory = temp;
        self.itemsOldCategory = tempOld;
        self.loadingData = false;
      }
    },
    async initMeasure() {
      const self = this;
      var temp = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/distance_measure",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.itemsMeasure = temp;
        self.initPropertyData();
      }
    },
    async addNewFunc(item) {
      const self = this;
      EventBus.$emit("loadingtillend");
      const obj = {
        name: item.addnew_name,
        distance: {
          total: Number(item.addnew_distance_number),
          unit: item.addnew_distance_unit,
        },
        category: item._id,
      };
      if (item.addnew_name && item.addnew_distance_number && item.addnew_distance_unit) {
        try {
          const res = await self.axios.post(
            process.env.VUE_APP_API + `/property/near_by?property_id=${self.propertyID}`,
            obj,
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem("Token") ||
                  self?.$store?.state?.resSignUpData?.access_token,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            self.$t("Alert.warn_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          self.initAll();
        }
      } else {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefill")}${
            !item.addnew_name || item.addnew_name.name == ""
              ? ` ${self.$t("SigninWhatNearBy.name")}`
              : ""
          }${
            !item.addnew_distance_number || item.addnew_distance_number == ""
              ? ` ${self.$t("SigninWhatNearBy.distance_number")}`
              : ""
          }${
            !item.addnew_distance_unit || item.addnew_distance_unit == ""
              ? ` ${self.$t("SigninWhatNearBy.distance_unit")}`
              : ""
          }`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    toggleEditListFunc(item) {
      item.editList = item.editList || false;
      // console.log(item);
      item.editList = !item.editList;
      // console.log(item);
      this.forceRerender();
    },
    async saveListFunc(item) {
      const self = this;
      EventBus.$emit("loadingtillend");
      const obj = {
        name: item.name,
        distance: {
          total: Number(item.distance.total),
          unit: item.distance.unit._id,
        },
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API + `/property/near_by?near_by_id=${item._id}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.initAll();
      }
    },
    async removeListFunc(item) {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.delete(
          process.env.VUE_APP_API + `/property/near_by?near_by_id=${item._id}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.initAll();
      }
    },
    clearAddNewFunc(item) {
      return (
        (item.addnew_name = ""),
        (item.addnew_distance_number = ""),
        (item.addnew_distance_unit = this?.mockupMeasureUnit?._id || "")
      );
    },
    saveFunc() {
      EventBus.$emit("loadingtillend");
      setTimeout(() => {
        EventBus.$emit("endloading");
      }, 3000);
    },
    checkChangeData() {
      const self = this;
      var cantleavethispage = false;
      var emptyValueCategory = false;
      self.itemsCategory?.map((el) => {
        const findSameCategory = self.itemsOldCategory?.find(
          (p) => p.main_category_id == el?.main_category_id
        );
        el?.category?.map((ele) => {
          const findSameCategoryName = findSameCategory?.category?.find(
            (p) => p._id == ele?._id
          );
          if (
            findSameCategoryName &&
            (ele?.addnew_name !== findSameCategoryName?.addnew_name ||
              ele?.addnew_distance_number !==
                findSameCategoryName?.addnew_distance_number)
          ) {
            emptyValueCategory = true;
          } else if (ele?.addnew_name || ele?.addnew_distance_number > 1)
            emptyValueCategory = true;
        });
      });
      if (emptyValueCategory) {
        cantleavethispage = true;
      } else cantleavethispage = false;
      return cantleavethispage;
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";
</style>

<style lang="scss" scoped>
.border-pink-focus {
  border-color: var(--pink) !important;
}

.field-icon {
  float: right;
  margin-left: -1.563rem;
  margin-top: -2.13rem;
  margin-right: 1.25rem;
  position: relative;
  z-index: 999;
}

.btn-measureunit-active {
  color: #fff;
  background-color: var(--info);
}

.form-control {
  height: 42px !important;
  line-height: 42 !important;
}

.contain-distance {
  overflow: auto !important;
}

::-webkit-scrollbar {
  height: 0.1rem;
}

::-webkit-scrollbar-track {
  background-color: #f3f3f3;
}

::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d4d4d4;
}

::-webkit-scrollbar-button {
  background-color: #d4d4d4;
}

::-webkit-scrollbar-corner {
  background-color: #f3f3f3;
  opacity: 0.4;
}
</style>
